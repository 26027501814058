<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Venue contacts</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  <router-link to="/venue">Venues </router-link>\  Venue contacts
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createVenueContact()"
                      class="mt-1 btn btn-primary"
                      style="color:#fff;"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add contact
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <form @submit.prevent="searchVenueContact" class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Name"
                      v-model="search.name"
                      outlined
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Mobile"
                      v-model="search.mobile"
                      outlined
                      @input="search.mobile = $event !== null ? $event : ''"
                      dense
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Phone"
                      v-model="search.phone"
                      outlined
                      @input="search.phone = $event !== null ? $event : ''"
                      clearable
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-btn
                      type="submit"
                      class="mt-1 btn btn-block btn-primary"
                      style="color: #fff;"
                      :loading="loading"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </form>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3" :class="sortedClass('name')" @click="sortBy('name')">Contact</th>
                  <th class="px-3">Email</th>

                  <th class="px-3">Mobile No.</th>
                  <th class="px-3">Phone No.</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="venueContact in sortedItems" :key="venueContact.id" >
                    <td class="px-3">
                      <a @click="editVenueContact(venueContact)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ venueContact.full_name }}
                      </a>
                      <span v-if="venueContact.is_primary" class="badge badge-danger">
                        Primary Contact
                      </span>
                    </td>
                    <td>
                      <a class="text-info mb-1" :href="'mailto:'+venueContact.email">
                        {{venueContact.email ? venueContact.email : 'NA' }}
                      </a>
                    </td>

                    <td>
                        {{ venueContact.mobile }}
                    </td>
                    <td>
                        {{ venueContact.phone }}
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editVenueContact(venueContact)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text v-if="!venueContact.is_primary" tag="div" class="navi-item">
                              <a @click="makePrimaryVenueContact(venueContact.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-check-circle"></i>
                                </span>
                                <span class="navi-text">Set as primary contact</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteVenueContact(venueContact.id)">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0" >
                    <td colspan="5" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  @input="getAllVenueContacts"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllVenueContacts"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "@/view/pages/view/practical-exam/venue/contact/CreateAndUpdate";
import VenueContactService from "@/services/practical-exam/venue/contact/VenueContactService";
import VenueService from "@/services/practical-exam/venue/VenueService";

const venue = new VenueService();
const venueContact = new VenueContactService();

export default {
  components: { CreateAndUpdate },
  data() {
    return{
      venueContacts:[],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      sort: {
        key: '',
        isAsc: false
      },
      search:{
        name:'',
        venue_id: '',
        mobile: '',
        phone: '',
      }
    }
  },
  mounted() {
    this.getAllVenueContacts();
  },
  computed: {
    sortedItems() {
      const list = this.venueContacts.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }

      return list;
    }
  },
  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    createVenueContact(){
      this.$refs['create-and-update'].createVenueContact();
    },
    editVenueContact(venueContact){
      this.$refs['create-and-update'].editVenueContact(venueContact);
    },
    getAllVenueContacts(){
      this.loading = true;
      venueContact
      .getByVenue(this.$route.params.venueId, this.search,this.page)
      .then(response => {
        this.venueContacts = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      })
          .catch((err) => {
            this.loading = false;
          });
    },
    searchVenueContact(){
      this.getAllVenueContacts();
    },
    deleteVenueContact(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            venueContact
                .delete(id)
                .then((response) => {
                  this.getAllVenueContacts()
                  this.$snotify.success("Venue contact deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    makePrimaryVenueContact(venueContactId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            venueContact
                .updatePrimaryContact(venueContactId)
                .then((response) => {
                  this.getAllVenueContacts()
                  this.$snotify.success("Saved as primary contact successfully.");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }
  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>