<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} Venue contact</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="venueContact.first_name"
                  :error="$v.venueContact.first_name.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  First name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.venueContact.first_name.$error">First name is required</span>
              <span class="text-danger" v-if="errors.first_name" >* {{ errors.first_name[0] }}</span>
            </v-col>



            <v-col cols="12" md="6">
              <v-text-field
                  v-model="venueContact.last_name"
                  :error="$v.venueContact.last_name.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Surname <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.venueContact.last_name.$error">Surname is required</span>
              <span class="text-danger" v-if="errors.last_name" >* {{ errors.last_name[0] }}</span>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                  v-model="venueContact.email"
                  :error="$v.venueContact.email.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Email <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.venueContact.email.$error">Invalid Email</span>
              <span class="text-danger" v-if="errors.email" >* {{ errors.email[0] }}</span>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                  v-model="venueContact.mobile"
                  @keypress="onlyNumber"
                  :error="$v.venueContact.mobile.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Mobile <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.mobile" >* {{ errors.mobile[0] }}</span>
              <span class="text-danger" v-if="$v.venueContact.mobile.$error">A phone or mobile is required</span>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                  v-model="venueContact.phone"
                  @keypress="onlyNumber"
                  :error="$v.venueContact.phone.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Phone <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.venueContact.phone.$error">A phone or mobile is required</span>
              <span class="text-danger" v-if="errors.phone" >* {{ errors.phone[0] }}</span>
            </v-col>
            <v-col cols="12">
              Role <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="venueContact.job_description"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.venueContact.job_description.$error">This information is required</span>
              <span class="text-danger" v-if="errors.job_description" >* {{ errors.job_description[0] }}</span>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                  label="Notes"
                  v-model="venueContact.notes"
                  outlined
                  dense
                  placeholder="Notes"
              >
              </v-textarea>
            </v-col>


          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text
            class="cancel-btn"
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn color="btn btn-primary"
            dark
            x-large
            @click="createOrUpdate()"
            :loading="loading">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VenueContactService from "@/services/practical-exam/venue/contact/VenueContactService";
import { required, requiredIf, email } from "vuelidate/lib/validators";

const venueContact = new VenueContactService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      loading: false,
      errors: [],
      venueContact: {
        venue_id: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        mobile: '',
        phone: '',
        is_primary: '0',
        job_description: '', 
        notes: ''  
      },
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      }
    }
  },
  validations: {
    venueContact: {
      first_name: { required },
      last_name: { required },
      mobile: {
          required : requiredIf(function () {
              return (!this.venueContact.phone);
          })
      },
      phone: {
          required : requiredIf(function () {
              return (!this.venueContact.mobile);
          })
      },
      job_description: { required },
      email: {required, email},
      is_primary: {},
      notes: {} 
    }
  },
  methods: {
    closeDialog(){
      this.dialog = false;
      this.$emit('refresh');
    },
    createVenueContact(){
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editVenueContact(venueContact){
      this.title = "Edit"
      this.dialog = true;
      this.edit = true;
      this.venueContact = venueContact;
    },
    createOrUpdate() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      this.venueContact.venue_id = this.$route.params.venueId;
      venueContact
          .create(this.venueContact)
          .then(response =>{
            this.loading = false;
            this.$snotify.success("Venue contact added");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      venueContact
          .update(this.venueContact.id,this.venueContact)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Venue contact updated");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm(){
      this.$v.$reset();
      this.venueContact = {
        venue_id: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        mobile: '',
        phone: '',
        is_primary: '0',
        job_description: '', 
        notes: ''  
      }
    },
    onlyNumber ($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
  }
}
</script>

<style scoped>

</style>